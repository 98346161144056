/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var BrowserDetect = {
      init: function () {
          this.browser = this.searchString(this.dataBrowser) || "Other";
          this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
      },
      searchString: function (data) {
          for (var i = 0; i < data.length; i++) {
              var dataString = data[i].string;
              this.versionSearchString = data[i].subString;

              if (dataString.indexOf(data[i].subString) !== -1) {
                  return data[i].identity;
              }
          }
      },
      searchVersion: function (dataString) {
          var index = dataString.indexOf(this.versionSearchString);
          if (index === -1) {
              return;
          }

          var rv = dataString.indexOf("rv:");
          if (this.versionSearchString === "Trident" && rv !== -1) {
              return parseFloat(dataString.substring(rv + 3));
          } else {
              return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
          }
      },

      dataBrowser: [
          {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"},
          {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
          {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
          {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
          {string: navigator.userAgent, subString: "Safari", identity: "Safari"},
          {string: navigator.userAgent, subString: "Opera", identity: "Opera"}
      ]

  };

BrowserDetect.init();

Modernizr.addTest('svgasimg', document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1'));


// Do things

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        svg4everybody();

        if(!Modernizr.touchevents){
          $("a[href*='tel:']").on('click',function(){
            return false;
          });
        }
        $("[data-block], a[href^='#']").on('click',function(){
          var $el = $($(this).data('block') || $(this).attr('href'));

          if($el.length > 0) {
            $("html").velocity("scroll", { offset: $el.offset().top, mobileHA: false });
            return false;
          }
        });

        //Instagram Feed
        if ($('.instagram-feed').length > 0) {
          var feed = new Instafeed({
              //get: 'tagged',
              //tagName: 'phyisolove',
              get: 'user',
              userId: '6068780837',
              clientId: '168db7d5baa84bf08f8a9708b9c2e19a',
              // accessToken: '6068780837.1677ed0.f527c884f1864d26bd9fafedb5b3d7bf',
              accessToken: '6068780837.1677ed0.dbb574d68a2745fdaf634ba8a9542f7d',
              limit: 8,
              resolution: 'standard_resolution',
              template: '<a class="instafeed__link" href="{{link}}"><div class="instafeed__image bg-img fade-lazyload lazyload bg" data-bgset="{{image}}" data-sizes="cover" data-parent-fit="cover" data-expand="20" style="background-size:cover;background-position:center center;background-repeat:no-repeat;"></div></a>'
          });
          feed.run();
        }


        // Hash scroll
        if(window.location.hash && $(window.location.hash).length > 0) {
          // console.log(window.location.hash);
          $(window.location.hash).velocity("scroll");
        }
        $("a.video").magnificPopup({
          type:'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 300,
           callbacks:{
            open:function(){
              var pInstance = $(".hero--home").data('vide');
              if(pInstance){
                pInstance = pInstance.getVideoObject();
                pInstance.pause();
              }
            },
            close:function(){
              var pInstance = $(".hero--home").data('vide');
              if(pInstance){
                pInstance = pInstance.getVideoObject();
                pInstance.play();
              }
            }
          },
          iframe: {
            markup: '<div class="mfp-iframe-scaler">'+
                      '<div class="mfp-close"></div>'+
                      '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                    '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

            patterns: {
              youtube: {
                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                id: 'v=', // String that splits URL in a two parts, second part should be %id%
                // Or null - full URL will be returned
                // Or a function that should return %id%, for example:
                // id: function(url) { return 'parsed id'; }

                src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
              },
              vimeo: {
                index: 'vimeo.com/',
                id: '/',
                src: '//player.vimeo.com/video/%id%?autoplay=1'
              },
              gmaps: {
                index: '//maps.google.',
                src: '%id%&output=embed'
              }
            }
          }
        });

        //fire the map if it exists
        var gmapScript ='https://maps.googleapis.com/maps/api/js?v=3.exp&callback=gmapInit&key='+GOOGLE_API_KEY;
        if($("#map-canvas").length !== 0){
          $.getScript( gmapScript )
            .done(function( script, textStatus ) {
              //console.log( script );
            })
            .fail(function( jqxhr, settings, exception ) {
              //$( "div.log" ).text( "Triggered ajaxError handler." );
          });
        }

      },
      finalize: function() {
        objectFitImages();

        stickyHeaderInit();

        $("nav.greedy").makeGreedy();

        // We probably don't need this right away
        $('.slider').each(function() {
          var visibleSlides = $(this).data('visible-slides');
          var pagination    = $(this).data('pagination');
          var navigation    = $(this).data('navigation');
          var loop          = $(this).data('loop');
          var autoplay      = $(this).data('autoplay');
          var params        = {
            direction: 'horizontal',
            loop: loop,
            slidesPerView: visibleSlides,
            speed: 600,
            paginationClickable: true,
            spaceBetween: 0
          };

          if (pagination) {
            params.pagination = '.swiper-pagination';
          }

          if (autoplay) {
            params.autoplay = autoplay;
          }

          if (navigation) {
            params.nextButton = '.swiper-next';
            params.prevButton = '.swiper-prev';
          }

          if (visibleSlides > 1) {
            params.breakpoints = {
              320: {
                slidesPerView: 1
              }
            };

            if (visibleSlides > 2) {
              params.breakpoints['640'] = {
                slidesPerView: 2
              };
            }

            if (visibleSlides > 3) {
              params.breakpoints['768'] = {
                slidesPerView: 3
              };
            }
          }

          var swiper = new Swiper ($(this).find('.swiper-container'), params);
        });

        // Team Loader
        if(document.querySelectorAll("[data-loader='team']").length > 0) {
          var teamLoader = new Loader('.loader--team', {
            triggerData: 'team',
            updateContent: true
          });
        }

        var menuLoader = new Loader('.nav--mobile--primary', {
          trigger: '.nav__toggle__trigger'
        });

        if ($('.loader--popup').length) {
          var popupLoader = new Loader('.loader--popup');
          setTimeout(function() { popupLoader.toggle(); }, popupLoader.loader.data('delay'));
        }

        // Mobiles
        mobileNavInit();

        $(".hero--keep-movin__cta").click(function() {
            $('html,body').animate({
                scrollTop: $(".main").offset().top
            }, 'slow');
        });
        $(".hero--internal .wrap_inner:after").click(function() {
            $('html,body').animate({
                scrollTop: $(".main").offset().top
            }, 'slow');
            console.log('pseudo clicked');
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

            // var hero = $('.hero').vide('{URL GOES HERE}', {
            //   volume: 0,
            //   playbackRate: 1,
            //   muted: true,
            //   loop: true,
            //   autoplay: true,
            //   position: '50% 50%', // Similar to the CSS `background-position` property.
            //   posterType: 'none', // Poster image type. "detect" — auto-detection; "none" — no poster; "jpg", "png", "gif",... - extensions.
            //   resizing: true, // Auto-resizing, read: https://github.com/VodkaBears/Vide#resizing
            //   bgColor: 'black', // Allow custom background-color for Vide div,
            //   className: 'video' // Add custom CSS class to Vide div
            // });
            // var resizeVideo = function(){
            //   hero.resize();
            // };
            // requestAnimationFrame(resizeVideo);

      },
      finalize: function() {
        someParallax({
          target: '.home--welcome',
          activeClass: 'parallax-active',
          layoutTrigger: {
            property: 'list-style-type',
            value: 'none'
          },
          scenes: {
            'default': {
              target: '.logo__background img',
              tween: {
                y: 400
              }
            }
          }
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'not_front': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('.js-gallery__item').magnificPopup({
          type: 'image',
          mainClass: 'mfp-fade',
          removalDelay: 300,
          gallery:{
            enabled:true
          },
          image: {
            markup: '<div class="mfp-figure">'+
                      '<div class="mfp-close"></div>'+
                      '<div class="mfp-img"></div>'+
                      '<div class="mfp-bottom-bar">'+
                        '<div class="mfp-title"></div>'+
                        '<div class="mfp-counter"></div>'+
                      '</div>'+
                    '</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

            cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

            //titleSrc: 'title', // Attribute of the target element that contains caption for the slide.
            // Or the function that should return the title. For example:
            titleSrc: function(item) {
              //console.log($(item.el).find('img').attr('title'));
              return $(item.el).find('img').attr('title');
            },

            verticalFit: true, // Fits image in area vertically

            tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
          },
          callbacks: {
            open: function() {
              $.magnificPopup.instance.next = function() {
                var self = this;
                self.wrap.removeClass('mfp-image-loaded');
                self.wrap.addClass('next');
                setTimeout(function() {
                  $.magnificPopup.proto.next.call(self);
                }, 400);
              };
              $.magnificPopup.instance.prev = function() {
                var self = this;
                self.wrap.removeClass('mfp-image-loaded');
                self.wrap.addClass('prev');
                setTimeout(function() {
                  $.magnificPopup.proto.prev.call(self);
                }, 400);
              };
            },
            imageLoadComplete: function() {
              var self = this;
              self.wrap.addClass('mfp-image-loading');
              setTimeout(function() {
                self.wrap.removeClass('mfp-image-loading prev next');
                self.wrap.addClass('mfp-image-loaded');
              }, 10);
            }
          }
        });

      }
    },
    'our_team': {
      init: function() {

      },
       finalize: function() {

       }
    },
    'contact': {
      init: function() {

      },
       finalize: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
        // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
          UTIL.fire(classnm);
          setTimeout(function(){
            UTIL.fire(classnm, 'finalize');
          },200);
        });
        // Fire common finalize JS
        setTimeout(function(){
          UTIL.fire('common', 'finalize');
          console.info( "main.js file has fired all commands." );
        },200);

    }
  };

  //fool the shit out of pagespeed insights
  function loadTheCSS(){
    var stylesheet = loadCSS( cssFile, document.getElementById("css-ref") );
    onloadCSS( stylesheet, function() {
      console.info( "Stylesheet has loaded." );
      $(document).ready(UTIL.loadEvents);
    });
  }
  var botPattern = "(Insights)";
  var re = new RegExp(botPattern, 'i');
  var userAgent = navigator.userAgent;
  if (re.test(userAgent)) {
      window.setTimeout(loadTheCSS, 5000);
  }else{
    loadTheCSS();
  }

})(jQuery); // Fully reference jQuery after this point.
